import Script from "next/script"
import useTranslation from "next-translate/useTranslation"

import { AbTestCtaVariant } from "@app/pages/home-social/[testVariant]"
import { BgColorProvider } from "@app/shared/utils/context/useBgColor"
import { useSaveMarketingCampaignParams } from "@app/shared/utils/marketingServerToServer"

import { NewHomePage } from "./NewHomePage"
import { HomeStickyCta } from "./StickyCta"

interface HomeContentProps {
  abTestVariant?: AbTestCtaVariant
  heroIsVisible: boolean
  updateHeroVisibility: (visibility: boolean) => void
}

export const HomeContent: React.FC<HomeContentProps> = ({
  abTestVariant,
  heroIsVisible,
  updateHeroVisibility,
}) => {
  const { t } = useTranslation("sell")
  const { lang } = useTranslation()

  useSaveMarketingCampaignParams()

  return (
    <>
      {["fr"].includes(lang) && (
        <>
          <Script
            async
            src="//pixel.mathtag.com/event/js?mt_id=1628525&mt_adid=260491&mt_exem=&mt_excl=&v1=&v2=&v3=&s1=&s2=&s3="
          />
        </>
      )}
      <BgColorProvider>
        <NewHomePage abTestVariant={abTestVariant} updateHeroVisibility={updateHeroVisibility} />
      </BgColorProvider>
      <HomeStickyCta
        description={t("sticky.description")}
        gtmEvent={{ value: "StickyFooter" }}
        heroIsVisible={heroIsVisible}
        label={t("sticky.title")}
        url="#hero-section"
      />
    </>
  )
}
