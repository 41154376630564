import { AbTestCtaVariant } from "@app/pages/home-social/[testVariant]"
import { useBgColor } from "@app/shared/utils/context/useBgColor"

import { Benefits } from "./Benefits"
import { Faq } from "./Faq"
import { GoogleReviews } from "./GoogleReviews"
import { HomeHero } from "./Hero"
import { HomePress } from "./Press"
import { Steps } from "./Steps"
import { homeSeparator } from "./styles.css"
import { ValuePropositionSection } from "./ValueProposition"

type NewHomePageProps = {
  abTestVariant?: AbTestCtaVariant
  updateHeroVisibility: (visibility: boolean) => void
}
export const NewHomePage: React.FC<NewHomePageProps> = ({ abTestVariant, updateHeroVisibility }) => {
  const { bgColor } = useBgColor()
  return (
    <main style={{ backgroundColor: bgColor, transition: "background-color .6s ease-in-out" }}>
      <HomeHero abTestVariant={abTestVariant} updateVisibility={updateHeroVisibility} />
      <hr className={homeSeparator} />
      <Steps />
      <ValuePropositionSection />
      <Benefits />
      <Faq />
      <GoogleReviews />
      <HomePress />
    </main>
  )
}
