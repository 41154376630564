import { Body } from "@casavo/habitat"
import useTranslation from "next-translate/useTranslation"
import { FC, useEffect } from "react"
import { useInView } from "react-intersection-observer"

import { AbTestCtaVariant } from "@app/pages/home-social/[testVariant]"
import { AddressAutocomplete } from "@app/shared/components/AddressAutocomplete"
import { MyImage } from "@app/shared/components/MyImage"
import { Typography } from "@app/shared/components/Typography"
import { useHTMLDialogElementSupported } from "@app/shared/hooks/useHTMLDialogElementSupported"

import {
  homeHero,
  homeHeroContent,
  homeHeroContentSubtitle,
  homeHeroContentTitle,
  homeHeroSection,
} from "../styles.css"

import { HeroImage } from "./HeroImage"
import { socialProof } from "./styles.css"

export type HomeHeroProps = {
  abTestVariant?: AbTestCtaVariant
  updateVisibility: (visibility: boolean) => void
}

export const HomeHero: FC<HomeHeroProps> = ({ abTestVariant = "control", updateVisibility }) => {
  const { lang, t } = useTranslation("sell")
  const { isHTMLDialogElementSupported } = useHTMLDialogElementSupported()

  const { inView, ref } = useInView({
    initialInView: true,
  })

  useEffect(() => {
    updateVisibility(inView)
  }, [inView, updateVisibility])

  const localeMapping: Record<string, string> = {
    en: "en",
    fr: "fr",
    it: "it",
  }
  const localeSrc = localeMapping[lang] || "it"

  return (
    <section ref={ref} className={homeHeroSection} id="hero-section">
      <div className={homeHero}>
        <div className={homeHeroContent}>
          <Typography className={homeHeroContentTitle} component="h1" variant="display">
            {t("sell:title")}
          </Typography>
          {isHTMLDialogElementSupported && (
            <Typography className={homeHeroContentSubtitle} component="h2" variant="h4">
              {t("sell:subtitle")}
            </Typography>
          )}
          <AddressAutocomplete />

          {abTestVariant === "b" && (
            <div className={socialProof}>
              <MyImage alt={""} height={48} src={"/images/home/social-proof.webp"} width={144} />
              <Body noMargin strong size="m">
                {t("sell:hero_socialproof_title")}
              </Body>
            </div>
          )}
        </div>
        <HeroImage
          images={{
            desktop: `/images/home/hero-${localeSrc}.webp`,
            mobile: `/images/home/hero-mobile-${localeSrc}.webp`,
          }}
        />
      </div>
    </section>
  )
}
